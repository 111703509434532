@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes animate {
  0%{
      transform: translateY(0) translateX(0px);
  }
  100%{
      transform: translateY(-225px) translateX(225px);
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100dvh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}
.background li {
  position: absolute;
  list-style: none;
  animation: animate linear infinite;
}

.full-height {
  /* Fall back to 100vh if env() is not supported */
  height: 100dvh; /* Fallback for browsers that don't support env() */
  height: calc(var(--vh, 1vh) * 100); /* Use our custom property */
}

.full-width {
  /* Fall back to 100vw if env() is not supported */
  width: 100dvw; /* Fallback for browsers that don't support env() */
  width: calc(var(--vw, 1vw) * 100); /* Use our custom property */
}

html, body {
  overscroll-behavior: none;
}

.no-emoji {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-feature-settings: "liga" 0;
}
